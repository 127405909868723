import { Box, Typography, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { formstyles } from "../../helpers/Styles";
import { useSettings } from "../../helpers/Hooks/Api";

const ChangeUsername = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  const { update, serverError } = useSettings("Username updated successfully");

  return (
    <NavLayout
      title="UpdateUsername"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt=""
              onClick={() => navigate(-1)}
              className=" absolute left-[14px] cursor-pointer"
            />

            <Typography className=" text-brandPurp text-[1.125rem] font-AR">
              Update Username
            </Typography>
          </Box>

          <Box width={"100%"} px={"20px"} margin={"0px auto"}>
            <Box
              sx={{
                marginTop: "40px",
              }}
            ></Box>

            <div className=" text-center text-brandPurp text-[0.875rem] max-w-[338px] mt-[58px] mb-[65px] m-[0_auto]">
              Choose a unique username for your account
            </div>
            <form onSubmit={handleSubmit(update)}>
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="Username"
                  type={"text"}
                  name="username"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("username", {
                    minLength: {
                      value: 3,
                      message: "Username name must be minimum of 3 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.username?.message}
                </Typography>
              </Box>

              <Typography variant="caption">{serverError}</Typography>

              <Box margin="100px auto" width="100%">
                <button
                  type="submit"
                  className=" rounded-[5px] bg-brandPurp w-full h-[56px] text-white text-[0.75rem] font-SF font-[600]"
                >
                  Save
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};

export default ChangeUsername;
