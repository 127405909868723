import { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NavLayout from "../NavLayout";
import { State, City } from "country-state-city";
import { useUpdateDetails } from "../../helpers/Hooks/Api";
import { formstyles } from "../../helpers/Styles";

const ChangeAddress = () => {
  // React-router navigation
  const navigate = useNavigate();

  // React-hook-form setup
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});

  // State for storing states and LGAs dynamically
  const [nigerianStates, setNigerianStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  // UseEffect to fetch Nigerian states once the component mounts
  useEffect(() => {
    const states = State.getStatesOfCountry("NG"); // 'NG' is the country code for Nigeria
    setNigerianStates(states);
  }, []);

  // Handler to fetch LGAs when a state is selected
  const handleStateChange = (event, value) => {
    setSelectedState(value); // Store the selected state
    if (value) {
      const localGovernments = City.getCitiesOfState("NG", value.isoCode); // Get LGAs by state ISO code
      setLgas(localGovernments);
    } else {
      setLgas([]); // Reset LGAs if no state selected
    }
  };

  // Function to handle form submission
  const { update, serverError } = useUpdateDetails();

  return (
    <NavLayout
      title="Update Address"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#fff",
            minHeight: "100vh",
            margin: "0 auto",
            paddingTop: "1px",
            paddingBottom: "4rem",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={"/assets/images/close.png"}
              alt="Close"
              onClick={() => navigate(-1)}
              className="absolute left-[14px] cursor-pointer"
            />

            <Typography className="text-brandPurp text-[1.125rem] font-AR">
              Update Address
            </Typography>
          </Box>

          {/* Form Section */}
          <Box width={"100%"} margin={"0px auto"} px={"20px"}>
            <Box sx={{ marginTop: "40px" }}></Box>

            <div className="text-center text-brandPurp text-[0.875rem] max-w-[338px] mt-[40px] mb-[50px] m-[0_auto]">
              Please input an address where you can receive claimed reward
              items.
            </div>

            {/* Address Update Form */}
            <form
              onSubmit={handleSubmit((data) =>
                update(
                  {
                    phone: "",
                    user_image: "",
                    bvn: "",
                    ...data,
                  },
                  "Address updated successfully"
                )
              )}
            >
              {/* Address Input */}
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="ADDRESS"
                  type={"text"}
                  name="address"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("address", {
                    minLength: {
                      value: 2,
                      message: "Address must be a minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.address?.message}
                </Typography>
              </Box>

              {/* Bus-Stop Input */}
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="BUS-STOP"
                  type={"text"}
                  name="bus_stop"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={formstyles}
                  {...register("bus_stop", {
                    minLength: {
                      value: 2,
                      message: "Bus-stop must be a minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.bus_stop?.message}
                </Typography>
              </Box>

              {/* Landmark Input */}
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="NEAREST LANDMARK"
                  type={"text"}
                  name="land_mark_area"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  sx={formstyles}
                  {...register("land_mark_area", {
                    minLength: {
                      value: 2,
                      message: "Landmark must be a minimum of 2 characters",
                    },
                  })}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.land_mark_area?.message}
                </Typography>
              </Box>

              {/* State Selection */}
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "0px 0 0px 3px",
                  marginBottom: "20px",
                }}
              >
                <Autocomplete
                  variant="standard"
                  disablePortal
                  id="combo-box-demo"
                  options={nigerianStates}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option?.isoCode}
                      value={option?.name}
                      {...props}
                    >
                      <Typography className="text-brandPurp text-[0.875rem]">
                        {option?.name}
                      </Typography>
                    </MenuItem>
                  )}
                  onChange={handleStateChange} // Handle state change
                  renderInput={(params) => (
                    <TextField
                      required
                      label="STATE"
                      variant="standard"
                      sx={formstyles}
                      {...params}
                      {...register("state", {
                        required: "State name is required",
                      })}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.state?.message}
                </Typography>
              </Box>

              {/* LGA Selection */}
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "0.5px solid #592E6D",
                  padding: "3px 0 3px 3px",
                }}
              >
                <Autocomplete
                  variant="standard"
                  disablePortal
                  id="combo-box-demo"
                  options={lgas}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <MenuItem
                      key={option?.name}
                      value={option?.name}
                      {...props}
                    >
                      <Typography className="text-brandPurp text-[0.875rem]">
                        {option?.name}
                      </Typography>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      required
                      label="LOCAL GOVERNMENT AREA"
                      variant="standard"
                      sx={formstyles}
                      {...params}
                      {...register("local_government_area", {
                        required: "Local government area is required",
                      })}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                <Typography
                  variant="body2"
                  sx={{ textAlign: "right", paddingRight: "5px" }}
                  color="red"
                >
                  {errors.local_government_area?.message}
                </Typography>
              </Box>

              {/* Server Error and Submit Button */}
              <Typography variant="caption">{serverError}</Typography>
              <Box margin="50px auto" width="100%">
                <button
                  type="submit"
                  className="rounded-[5px] bg-brandPurp w-full h-[56px] text-white text-[0.75rem] font-SF font-[600]"
                >
                  Save
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      }
    />
  );
};

export default ChangeAddress;
