import { useNavigate } from "react-router-dom";

const StartSaving = () => {
  const navigate = useNavigate();
  return (
    <div className=" w-full py-[100px] bg-[#F8F8FB] flex flex-col lg:flex-row items-center gap-x-[50px] px-[7%] md:px-[13.8%]">
      <div>
        {" "}
        <p className=" text-[1rem] text-center md:text-left font-SF font-[300] mb-[24px]">
          ONECAP FITS ALL
        </p>
        <p className=" text-[2rem] md:text-[2.5rem] text-center md:text-left font-[400] mb-[24px] max-w-[39.125rem]">
          Savings, gaming and financial education, in one single application.
        </p>
        <p className=" text-[1.125rem] md:text-[0.8rem] text-center md:text-left font-[300] mb-[24px] leading-[36px] font-SF max-w-[35.8122rem]">
          Saving money and investing are great financial habits to develop, but
          that’s not always easy with daily demands tugging at your purse.
          Joining OneCap’s prize-linked savings pools will give you extra
          motivation to save first, and then stand a chance to win big or small
          prizes.{" "}
        </p>
        <p className=" text-[1.125rem] md:text-[0.8rem] text-center leading-[36px] md:text-left font-[300] mb-[24px] font-SF max-w-[35.8122rem]">
          Combining the fun of gaming with the thrill of no-risk draws and
          benefits and security of a savings account, you'll increase your
          chances to win money any time, any day - just by saving money.
        </p>
      </div>

      <div className=" bg-[#520692] rounded-[20px] p-[25px] text-white font-SF self-start mt-[20px] md:mt-0">
        <p className=" text-[1.5rem] font-[300]">
          Start saving with as low as &#8358;1,000
        </p>
        <p className=" text-[1.125rem] md:text-[0.8rem] font-[300] mt-[12px] mb-[27px]">
          Join other customers who have signed up to OneCap fo save and win.
        </p>
        <button
          className="py-[15px] px-[41px] rounded-[30px] bg-white text-black text-[1.125rem] font-SF font-[700] mb-[20px]"
          onClick={() => navigate("/signup")}
        >
          Sign up now
        </button>
      </div>
    </div>
  );
};

export default StartSaving;
