import NavLayout from "../NavLayout";
import { useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";
import { useEffect, useState } from "react";
import { Box, Typography, Snackbar } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { onMessageListener } from "../../Auth/firebase";
import { ArrowBackIos } from "@mui/icons-material/";

const TransactionHistory = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [notification, setNotification] = useState({ title: "", body: "" });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchTransactions = async () => {
    await axios
      .get("/transactions", config)
      .then(function (response) {
        setIsloading(false);
        setTransactions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, []);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <NavLayout
        title="Dashboard"
        content={
          <div className="flex flex-col max-w-[500px] mx-auto pt-[20px] h-[100vh] max-h-[100vh]">
            <div className="flex gap-[30%] border-b-1 border-opacity-50 border-[#FF1CF7] py-[20px] px-[20px] h-[10%]">
              <ArrowBackIos
                sx={{ fontSize: "16px", cursor: "pointer", color: "#fff" }}
                onClick={() => {
                  navigate(-1);
                }}
              />
              <p className="text-white">Transaction History</p>
            </div>

            <div className="h-[40%] text-white text-opacity-30">
              <hr />
            </div>

            <div className="bg-white h-[60%] relative flex justify-center">
              <div className="bg-white outline outline-1 outline-offset-0 outline-[#FF1CF7]  shadow-[0px_4px_4px_0px_#00000040] rounded-lg absolute top-[-60%] bottom-[5%] overflow-y-auto max-h-[800px]  min-w-[90%] px-[10px] py-[5px] mx-[20px]">
                {isLoading ? (
                  <div className="h-[100vh] overflow-auto">
                    {Array(10)
                      .fill()
                      .map((_, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-center gap-[10px] cursor-pointer py-[10px] border-b border-[#FF1CF7] border-opacity-50"
                        >
                          <img
                            src="/assets/images/transactIcon.png"
                            alt="transaction"
                          />
                          <div className="flex flex-col gap-[10px]">
                            <div className="animate-pulse h-[10px] w-[200px] opacity-75 rounded bg-[#592E6D]"></div>
                            <div className="animate-pulse h-[10px] w-[70px] opacity-75 rounded bg-[#592E6D]"></div>
                          </div>

                          <div className="flex flex-1 items-center pl-[auto]">
                            <div className="ml-[auto]">
                              <div className="animate-pulse h-[10px] w-[50px] opacity-75 rounded bg-[#592E6D]"></div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : transactions.length > 0 ? (
                  transactions.map((transaction) => {
                    return (
                      <div
                        key={transaction.id}
                        className="flex justify-between items-center gap-[10px] cursor-pointer py-[10px] border-b border-[#FF1CF7] border-opacity-50"
                        onClick={() => {
                          navigate(`/dashboard/transactions/${transaction.id}`);
                        }}
                      >
                        <img
                          src="/assets/images/transactIcon.png"
                          alt="transaction"
                        />
                        <div>
                          {/* Ensure description is not null/undefined */}
                          <p className="text-[0.75rem] font-[900] text-[#592E6D] uppercase">
                            {(
                              transaction.description ||
                              "No description available"
                            ).slice(0, 30)}
                            ...
                          </p>
                          <p className="font-[400] text-[0.6rem] mt-[6px] text-[#592E6D]">
                            {transaction.time_stamp?.slice(0, 10) || "N/A"}
                          </p>
                        </div>

                        <div className="flex flex-1 items-center pl-[auto]">
                          <div className="ml-[auto]">
                            <p className="text-[0.9rem] text-[#592E6D] font-[600]">
                              &#8358;
                              {parseFloat(transaction.amount)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Typography>No Transaction history</Typography>
                )}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default TransactionHistory;
