import { Link } from "react-router-dom";

function Footer({ footerRef }) {
  return (
    <div
      ref={footerRef}
      className=" w-full bg-white px-[7%] md:px-[13.8%] flex flex-col justify-center py-[72px] md:py-[100px] text-black font-SF"
    >
      <div className="hidden w-full md:flex">
        <div className="w-[40%]">
          <p className="text-[2.25rem] font-[400]"> Have any questions?</p>
          <button className="bg-[#290349] rounded-[30px] p-[15px_20px] mt-[38px] text-white text-[1.125rem] font-[700]">
            <a
              href="mailto:hello@onecap.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact support
            </a>
          </button>
        </div>

        <div className="flex w-[60%] justify-between">
          <div className="flex flex-col">
            <p className="text-[1rem] font-[300] mb-[20px]">ONECAP</p>
            <Link to={""} className="text-[0.8rem] font-[300] mb-[10px]">
              Naira Gamified Savings Pool
            </Link>
            <Link to={""} className="text-[0.8rem] font-[300] mb-[10px]">
              Ultimate Jackpot Savings Pool
            </Link>
            <Link to={""} className="text-[0.8rem] font-[300] mb-[10px]">
              Financial Quiz
            </Link>
            <Link to={""} className="text-[0.8rem] font-[300] mb-[10px]">
              League
            </Link>
            <Link to={""} className="text-[0.8rem] font-[300] mb-[10px]">
              Tokens{" "}
            </Link>
          </div>

          <div className="flex flex-col">
            <p className="text-[1rem] font-[300] mb-[20px]">INFORMATION</p>
            <Link
              to={"/about-us"}
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              About
            </Link>
            <Link to={"/faq"} className="text-[0.8rem] font-[300] mb-[10px]">
              FAQs
            </Link>
            <Link
              to={"/privacy-policy"}
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Privacy Notice
            </Link>
            <Link
              to={"/terms-and-conditions"}
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Terms and Conditions
            </Link>
          </div>

          <div className="flex flex-col">
            <p className="text-[1rem] font-[300] mb-[20px]">SOCIAL MEDIA</p>
            <a
              href="https://www.facebook.com/share/1XTnTzUuDu/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Facebook
            </a>

            <a
              href="https://twitter.com/OnecapAfrica"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/onecapafrica/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Instagram
            </a>
            <a
              href="https://tiktok.com/@onecapafrica"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              TikTok
            </a>
            <a
              href="https://chat.whatsapp.com/IinexFTU680HJTvZBZPIyg"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[0.8rem] font-[300] mb-[10px]"
            >
              Community
            </a>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-x-[24px] md:hidden">
        <a
          href="https://www.facebook.com/share/1XTnTzUuDu/?mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/facebookBlack.png" alt="Onecap Facebook" />
        </a>

        <a
          href="https://www.instagram.com/onecapafrica/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/instagramBlack.png" alt="Onecap Instagram" />
        </a>

        <a
          href="https://twitter.com/OnecapAfrica"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/xBlack.png" alt="Onecap X" />
        </a>

        <a
          href="https://tiktok.com/@onecapafrica"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/images/tiktokBlack.png"
            alt="Onecap Tiktok"
            className="w-[25px] h-[25px]"
          />
        </a>

        <a
          href="https://chat.whatsapp.com/IinexFTU680HJTvZBZPIyg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/images/communityBlack.png"
            alt="Onecap Community"
            className="w-[25px] h-[25px]"
          />
        </a>
      </div>

      <div className="text-[1.125rem] text-black font-[700] font-SF mt-[24px] underline hover:underline-offset-4 md:hidden">
        <a
          href="mailto:hello@onecap.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          hello@onecap.com
        </a>
      </div>

      <div className="flex gap-x-[36px] md:hidden">
        <a
          href="mailto:hello@onecap.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[1.125rem] text-black font-[300] font-SF mt-[24px]"
        >
          Help
        </a>
        <Link
          to={"/about-us"}
          className="text-[1.125rem] text-black font-[300] font-SF mt-[24px]"
        >
          About
        </Link>
        <Link
          to={"/privacy-policy"}
          className="text-[1.125rem] text-black font-[300] font-SF mt-[24px]"
        >
          Privacy Policy
        </Link>
      </div>

      <div className="w-full text-center text-[0.8rem] mt-[80px] font-[300]">
        Copyright Onecap Ltd. {new Date().getFullYear()}, all rights reserved
      </div>
    </div>
  );
}

export default Footer;
