import { Box, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material/";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../../helpers/axios";
import { useState, useEffect } from "react";
import NavLayout from "../NavLayout";

const ViewTransaction = () => {
  const [isLoading, setIsloading] = useState(true);
  const location = useLocation();
  const ref = location.pathname.split("/")[3];
  const token = localStorage.getItem("token");
  const [investment, setInvestment] = useState({});
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchDetail = async () => {
    try {
      const response = await axios.get(`/transactions/${ref}`, config);
      setIsloading(false);
      setInvestment(response?.data ?? {}); // Handle response safely
    } catch (error) {
      console.log(error);
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchDetail();
  }, []);

  const navigate = useNavigate();

  return (
    <NavLayout
      title="Savings Details"
      content={
        <Box maxWidth={"500px"} sx={{ margin: "0 auto" }}>
          <Box sx={{ paddingBottom: "20px" }}>
            <Box
              sx={{
                padding: "24px 20px",
                borderBottom: "0.1px solid #FFFFFF80",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                }}
              >
                <ArrowBackIos
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Box textAlign={"center"} width="100%">
                  <Typography variant="body1">Savings Details</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ padding: "24px 20px" }}>
            <Box
              sx={{
                background: "#fff",
                height: "600px",
                overflow: "scroll",
                border: "0.01px solid rgba(255,28,247,0.4)",
                borderRadius: "10px",
                px: "10px",
                color: "#592E6D",
                mb: "50px",
              }}
            >
              {isLoading ? (
                <Box>
                  {/* Placeholder while loading */}
                  <InvestmentCard
                    title={"Amount Deposited"}
                    value={<Placeholder />}
                  />
                  <InvestmentCard title={"Type"} value={<Placeholder />} />
                  <InvestmentCard title={"Date"} value={<Placeholder />} />
                  <InvestmentCard title={"Remarks"} value={<Placeholder />} />
                  <InvestmentCard
                    title={"Description"}
                    value={<Placeholder />}
                  />
                </Box>
              ) : (
                <Box>
                  <InvestmentCard
                    title={"Amount Deposited"}
                    value={
                      investment.amount
                        ? `₦${investment.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : undefined
                    }
                  />
                  <InvestmentCard
                    title={"Type"}
                    value={investment.category ?? undefined}
                  />
                  <InvestmentCard
                    title={"Date"}
                    value={investment.time_stamp?.slice(0, 10) ?? undefined}
                  />
                  <InvestmentCard
                    title={"Remarks"}
                    value={investment.remarks ?? undefined}
                  />
                  <InvestmentCard
                    title={"Description"}
                    value={
                      investment.description
                        ? investment.description.charAt(0).toUpperCase() +
                          investment.description.slice(1)
                        : undefined
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

const InvestmentCard = ({ title, value }) => (
  <Box
    display={"flex"}
    alignItems="center"
    justifyContent={"space-between"}
    borderBottom={"0.2px solid gray"}
    py={"10px"}
  >
    <Typography
      fontWeight={400}
      variant="caption"
      // sx={{ marginRight: "30px" }}
    >
      {title}
    </Typography>
    <Typography fontWeight={400} variant="caption" sx={{ marginLeft: "30px" }}>
      {value ?? "N/A"}
      {/* Fallback to 'Undefined' when value is null/undefined */}
    </Typography>
  </Box>
);

const Placeholder = () => (
  <div className="animate-pulse rounded opacity-75 w-[100px] h-[10px] bg-brandPurp"></div>
);

export default ViewTransaction;
