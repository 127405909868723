import Signup from "./Auth/Signup";
import Signin from "./Auth/Signin";
import Reset from "./Auth/Reset";
import Otp from "./Auth/Otp";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Wallet from "./components/Wallet";
import DepositHistory from "./components/Wallet/DepositHistory";
import WithdrawalHistory from "./components/Wallet/WithdrawalHistory";
import NavLayout from "./components/NavLayout";
import { Investments, Portfolio } from "./components/Investments";
import Personalinfo from "./components/Usersetting/Personalinfo";
import Tnc from "./Landing/Tnc";
import Settings from "./components/Usersetting/Settings";
import Notification from "./components/Usersetting/Notification";
import Payout from "./components/Usersetting/Payout";
import Bill from "./components/Bill";
import Terms from "./components/Usersetting/Terms";
import How from "./components/Usersetting/How";
import Changephonenumber from "./components/Usersetting/Changephone";
import Changename from "./components/Usersetting/Changename";
import UploadId from "./components/Usersetting/Uploadid";
import ViewInvestment from "./components/Investments/ViewInvestment";
import Bank from "./components/Usersetting/Banks";
import Avatar from "./components/Usersetting/Avatar";
import ReferralsIndex from "./components/Referrals/ReferralsIndex";
import VerifyResetToken from "./Auth/VerifyResetToken";
import ChangePassword from "./Auth/ChangePassword";
import ReferralDetails from "./components/Referrals/ReferralDetails";
import ViewTransaction from "./components/Dashboard/ViewTransaction";
import Privacy from "./Landing/Privacy";
import RewardsIndex from "./components/Rewards/RewardsIndex";
import RewardsHistory from "./components/Rewards/RewardsHistory";
import Landing from "./Landing/Landing";
import AboutUs from "./Landing/AboutUs";
import FAQ from "./Landing/FAQ";
import GamfiPool from "./components/Pools/GamfiPool/GamfiPool";
import JackpotPool from "./components/Pools/JackpotPool/JackpotPool";
import Quiz from "./components/Quiz/Quiz";
import QuizResult from "./components/Quiz/QuizResult";
import QuizBoard from "./components/Quiz/QuizBoard";
import TransactionHistory from "./components/Dashboard/TransactionHistory";
import ViewSavings from "./components/Wallet/ViewSavings";
import RewardsMenu from "./components/Rewards/RewardsMenu";
import TrackReward from "./components/Rewards/TrackReward";
import BVN from "./components/Usersetting/BVN";
import ChangeAddress from "./components/Usersetting/ChangeAddress";
import Changephone from "./components/Usersetting/Changephone";
import TrackRewards from "./components/Rewards/TrackRewards";
import ChangeUsername from "./components/Usersetting/ChangeUsername";
import DepositsWithdrawals from "./components/Wallet/DepositsWithdrawals";
import { Onboarding } from "./Landing/Onboarding";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/onboarding" element={<Onboarding />} />

        <Route exact path="/:user/:referral/:code" element={<Landing />} />
        <Route exact path="/:user/:referral/*" element={<Landing />} />
        <Route exact path="/terms-and-conditions" element={<Tnc />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/reset-password" element={<Reset />} />
        <Route
          exact
          path="/reset-password/:uidb/:token"
          element={<VerifyResetToken />}
        />
        <Route exact path="/change-password" element={<ChangePassword />} />

        <Route exact path="/signup/verify" element={<Otp />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/dashboard/wallet" element={<Wallet />} />
        <Route
          exact
          path="/transaction-history"
          element={<TransactionHistory />}
        />
        <Route
          exact
          path="/dashboard/wallet/deposit-history"
          element={<DepositHistory />}
        />
        <Route
          exact
          path="/dashboard/wallet/deposit-withdrawal-history"
          element={<DepositsWithdrawals />}
        />
        <Route exact path="/wallet/:ref" element={<ViewSavings />} />
        <Route exact path="/savings-details/:ref" element={<ViewSavings />} />
        <Route
          exact
          path="/dashboard/wallet/withdrawal-history"
          element={<WithdrawalHistory />}
        />
        <Route
          exact
          path="/dashboard/transactions/:ref"
          element={<ViewTransaction />}
        />
        <Route exact path="/gamefi-pool" element={<GamfiPool />} />
        <Route exact path="/jackpot-pool" element={<JackpotPool />} />
        <Route exact path="/investments" element={<Investments />} />
        <Route exact path="/investments/:ref" element={<ViewInvestment />} />
        <Route exact path="/investments/portfolio" element={<Portfolio />} />
        <Route exact path="/bills" element={<Bill />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/settings/personalinfo" element={<Personalinfo />} />
        <Route exact path="/settings/walletpayout" element={<Payout />} />

        <Route exact path="/settings/terms" element={<Terms />} />
        <Route
          exact
          path="/settings/setusername"
          element={<ChangeUsername />}
        />
        <Route exact path="/settings/setname" element={<Changename />} />
        <Route exact path="/settings/setphone" element={<Changephone />} />
        <Route exact path="/settings/setaddress" element={<ChangeAddress />} />
        <Route exact path="/settings/banks" element={<Bank />} />
        <Route exact path="/settings/id" element={<UploadId />} />
        <Route exact path="/settings/bvn" element={<BVN />} />

        <Route
          exact
          path="/settings/setphone"
          element={<Changephonenumber />}
        />
        <Route exact path="/settings/uploadid" element={<UploadId />} />
        <Route exact path="/settings/avatar" element={<Avatar />} />

        <Route exact path="/our-process" element={<How />} />
        <Route
          exact
          path="/settings/notifications"
          element={<Notification />}
        />
        <Route exact path="/referrals" element={<ReferralsIndex />} />
        <Route exact path="/referral-details" element={<ReferralDetails />} />
        <Route exact path="/rewards-menu" element={<RewardsMenu />} />
        <Route exact path="/track-rewards" element={<TrackRewards />} />
        <Route exact path="/track-reward" element={<TrackReward />} />
        <Route exact path="/rewards" element={<RewardsIndex />} />
        <Route exact path="/quiz" element={<Quiz />} />
        <Route exact path="/quiz-result" element={<QuizResult />} />
        <Route exact path="/quiz-board" element={<QuizBoard />} />
        <Route
          exact
          path="/point-details/:is_service/:rhPage/:current_user_id"
          element={<RewardsHistory />}
        />
        <Route path="*" element={<NavLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
