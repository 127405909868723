import { Modal } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../helpers/Context";
import { DayLeft } from "../../Timer";
import { useJoinPool } from "../../../helpers/Hooks/Api";

const PoolForm = ({ open, onClose, cycle }) => {
  const navigate = useNavigate();
  const [modalPage, setModalPage] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [investmentAmount, setInvestmentAmount] = useState(2000);
  const { joinGamefiPool, joinPoolError } = useJoinPool("/join-savings");

  const { state } = useContext(Context);
  const { poolInfo } = state;
  const [error, setError] = useState("");

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const shouldButtonActivate = () => {
      if (
        poolInfo?.has_pool_started !== null &&
        investmentAmount >= 2000 &&
        investmentAmount <= 20000 &&
        investmentAmount % 2000 === 0
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    };
    shouldButtonActivate();
  }, [investmentAmount]);

  const handleChange = (e) => {
    let amount = e.target.value;
    let floatAmount = parseFloat(amount.replaceAll(",", ""));
    setInvestmentAmount(floatAmount);
    if (floatAmount < 2000) {
      setError("Minimum savings is N2,000");
    } else if (floatAmount >= 2000) {
      if (floatAmount % 2000 !== 0) {
        setError("Savings can only be done in multiples of N2,000");
      } else if (floatAmount > 20000) {
        setError("You can only save a maximum of N20,000");
      } else {
        setError("");
      }
    } else {
      setError("");
    }
  };

  const handleJoinGamefiPool = async (e) => {
    e.preventDefault();
    const amount_saved = investmentAmount;
    await joinGamefiPool(
      {
        amount_saved,
      },
      setModalPage
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={`flex items-center ${
        modalPage === 1 ? "md:items-start" : " md:items-center"
      }`}
    >
      <>
        {modalPage === 1 && (
          <section
            className={`w-[24.5rem] rounded-[10px] bg-[#fff] bg-blend-overlay mx-[auto] p-[24px_18px] transition-transform duration-700 ease-in-out ${
              modalPage === 1 ? " scale-100" : "scale-0"
            }`}
          >
            <div className="text-center text-[1.125rem] text-[#592E6D] font-SF font-[600]">
              Naira Gamified Savings Pool ({cycle})
            </div>
            <div className="w-full h-[172px] rounded-[10px] bg-[rgba(227,196,253,0.50)] shadow-[0px_8px_24px_0px_rgba(0,0,0,0.15)] mt-[42px] relative">
              <div className="w-[9.375rem] h-[36px] rounded-[30px] border-[2px] border-[#FF1CF7] bg-[#592E6D] absolute top-[-18px] left-[6.4375rem] text-[0.875rem] text-[#fff] font-SF font-[600] flex items-center justify-center">
                WINNINGS
              </div>
              <div className=" text-[2.25rem] text-[#592E6D] font-AC font-[700] text-center pt-[24px]">
                MONTHLY
              </div>
              <div className="text-center text-[0.875rem] text-[#592E6D] font-SF font-[600]">
                Price Draw in
              </div>
              <div className="w-fit flex items-center gap-x-[6px] m-[0_auto] mt-[12px]">
                {DayLeft({ time: poolInfo?.raffle_draw_date })}

                <div className=" w-[3.75rem] h-[30px] bg-[#592E6D] rounded-[4px] flex items-center justify-center text-[#fff] text-[2.25] font-AC font-[400]">
                  Days
                </div>
              </div>
            </div>
            <div className=" text-[0.875rem] text-[#592E6D] font-SF font-[700] text-center mt-[9px]">
              <span>One entry</span> <span className=" font-[200]">=</span>
              <span>N</span>
              <span className="text-[1.25rem]">2,000.</span>00
            </div>
            <div className=" text-center text-[0.875rem] text-[#592E6D] font-SF font-[400] mt-[24px]">
              {formatDate(poolInfo?.pool_start_date)} -{" "}
              {formatDate(poolInfo?.pool_end_date)}
            </div>
            <form className="w-full" onSubmit={handleJoinGamefiPool}>
              <div className="text-center text-[0.875rem] text-[#592E6D] font-AC font-[600] mt-[24px]">
                Enter the amount you want to save
              </div>

              <div className="w-[20rem] h-[56px] rounded-[5px] p-[3px_0_3px_3px] border-[0.5px] border-[#592E6D] m-[0_auto] mt-[24px]">
                <NumericFormat
                  thousandSeparator=","
                  required
                  min={2000}
                  valueIsNumericString={true}
                  value={investmentAmount}
                  name="investmentAmount"
                  className="w-[99%] outline-[2px] outline-[transparent] border-none p-[6px] rounded-[5px] text-[#592E6D] text-[1rem]"
                  onChange={handleChange}
                />
              </div>
              <div className=" text-[0.75rem] text-[red] ml-[20px] mt-[5px]">
                {error}
              </div>
              <div className=" text-[0.75rem] text-[red] ml-[20px] mt-[5px]">
                {joinPoolError}
              </div>

              <button
                type="submit"
                className="w-[20rem] h-[56px] rounded-[5px] text-[0.75rem] bg-[#592E6D] text-[#fff] font-SF font-[600] m-[0_auto] mt-[24px] flex items-center justify-center cursor-pointer disabled:bg-[rgba(89,46,109,0.30)] disabled:text-[rgba(255,255,255,0.20)]"
                disabled={isButtonDisabled}
              >
                Save and Win
              </button>
            </form>
          </section>
        )}

        {modalPage === 2 && (
          <section
            className={`w-[24.2574rem] h-[230px] m-[0_auto] p-[10px] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col`}
          >
            <div
              className="flex self-start justify-end w-full cursor-pointer "
              onClick={() => onClose()}
            >
              <img
                src="/assets/images/closeBold.png"
                alt=""
                className=" h-[24px] w-[24px]"
              />
            </div>
            <div className="text-[1rem] text-[#592E6D] font-SF font-[600] flex items-center justify-center mt-[37px]">
              <span className="pt-[5px]"> Success!</span>
              <img
                src="/assets/images/success.gif"
                alt=""
                className="w-[50px] h-[50px]"
              />
            </div>
            <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]">
              You successfully joined Onecap’s Naira Gamefi Savings Pool -{" "}
              {cycle}{" "}
            </div>
          </section>
        )}

        {modalPage === 3 && (
          <section
            className={`w-[24.2574rem] h-[230px] m-[0_auto] p-[10px] mt-[2rem] transition-transform duration-700 ease-in-out bg-[#fff] shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20),0px_9px_46px_0px_rgba(0,0,0,0.12),0px_24px_38px_0px_rgba(0, 0, 0, 0.14)] rounded-[4px] flex flex-col items-center`}
          >
            <div
              className="flex self-start justify-end w-full cursor-pointer "
              onClick={() => onClose()}
            >
              <img
                src="/assets/images/closeBold.png"
                alt=""
                className=" h-[24px] w-[24px]"
              />
            </div>
            <div className="text-[1rem] text-[#592E6D] font-SF font-[600] flex items-center justify-center mt-[37px]">
              Oops! That didn’t work
            </div>
            <div className=" max-w-[20.375rem] text-center m-[0_auto] text-[0.75rem] text-[#592E6D] font-SF font-[400]">
              {joinPoolError
                ? joinPoolError
                : "Something went wrong! Please try again."}
            </div>

            <div className="flex items-center gap-x-[18px] mt-[23px]">
              <button
                onClick={() => onClose()}
                className=" underline text-[0.875rem] text-[#290349] font-EN font-[300]"
              >
                Back to pool
              </button>{" "}
              <button
                className=" text-[#fff] w-[9.5rem] h-[32px] bg-[#702F90] rounded-[5px] border-[1px] border-[rgba(255,28,247,0.50)]"
                onClick={() => navigate("/dashboard/wallet")}
              >
                Fund Wallet
              </button>
            </div>
          </section>
        )}
      </>
    </Modal>
  );
};

export default PoolForm;
