import { useContext } from "react";
import { FilterList } from "@mui/icons-material";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { useUserInfo } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(89, 46, 109, 0.8)",
        },
      },
    },
  },
});
const ReferralLeaderBoard = () => {
  useUserInfo();
  const { state } = useContext(Context);
  const { referral_rewards_details, userInfo, referral_details } = state;

  const userRank = () => {
    if (!userInfo?.user?.wallet_id || referral_rewards_details?.length === 0) {
      return ""; // If the wallet_id or referral data is missing, return an empty string
    }
    const userIndex = referral_rewards_details?.findIndex(
      (detail) => detail.referrer.wallet_id === userInfo.user.wallet_id
    );
    return userIndex !== -1 ? userIndex + 1 : "";
  };

  const userData = (
    <TableRow
      sx={{
        backgroundColor: "#D8B1D4",
        borderRadius: "20px",
        border: "1px solid #D8B1D4",
        "& td": {
          borderBottom: "none", // Remove the default bottom border for all cells in this row
        },
      }}
    >
      <TableCell
        sx={{
          textAlign: "center",
          borderRadius: "20px 0 0 20px", // Rounded left corners
        }}
      >
        {userRank() === "" ? (
          <span></span>
        ) : userRank() === 1 ? (
          <img src="/assets/images/1stRank.png" alt="1st Rank" />
        ) : userRank() === 2 ? (
          <img src="/assets/images/2ndRank.png" alt="2nd Rank" />
        ) : userRank() === 3 ? (
          <img src="/assets/images/3rdRank.png" alt="3rd Rank" />
        ) : (
          `#${userRank()}`
        )}
      </TableCell>
      <TableCell
        sx={{
          textAlign: "center",
          // borderBottom: "0.2px solid #FF1CF7",
        }}
      >
        {userInfo?.user?.username}
      </TableCell>
      <TableCell
        sx={{
          textAlign: "center",
          borderBottom: "0.2px solid #FF1CF7",
        }}
      >
        {referral_details?.referrals?.length}
      </TableCell>

      <TableCell
        sx={{
          textAlign: "center",
          borderRadius: "0 20px 20px 0", // Rounded right corners
        }}
      >
        {userInfo?.referral_point}
      </TableCell>
    </TableRow>
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "0.5px solid #FF1CF7",
          color: "#290349",
          padding: "15px 0",
        }}
      >
        <Typography variant="body2">League Details</Typography> <FilterList />
      </Box>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#290349",
                  width: "10%",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#290349",
                  width: "50%",
                  textAlign: "center",
                }}
              >
                <p className="font-MV font-[400]">Username</p>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#290349",
                  width: "20%",
                  textAlign: "center",
                }}
              >
                <p className="font-MV font-[400]">Total Referrals</p>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#290349",
                  width: "20%",
                  textAlign: "center",
                }}
              >
                <p className="font-MV font-[400]">Tokens Earned</p>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>{userData}</TableBody>

          <TableBody>
            {referral_rewards_details.length > 0
              ? referral_rewards_details?.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {index + 1 === 1 ? (
                        <img src="/assets/images/1stRank.png" alt="1st Rank" />
                      ) : index + 1 === 2 ? (
                        <img src="/assets/images/2ndRank.png" alt="2nd Rank" />
                      ) : index + 1 === 3 ? (
                        <img src="/assets/images/3rdRank.png" alt="3rd Rank" />
                      ) : (
                        `#${index + 1}`
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderRight: "0.2px solid #FF1CF7",
                      }}
                    >
                      {detail?.referrer?.username}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderRight: "0.2px solid #FF1CF7",
                      }}
                    >
                      {detail?.referrals}
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {detail?.total_point_earned}
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default ReferralLeaderBoard;
