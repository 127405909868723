import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export const Onboarding = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = 3;
  const onBoarded = localStorage.getItem("onboarded");
  const intervalRef = useRef(null);

  const firstSlide = () => (
    <div
      className="md:max-w-[31.25rem] md:m-[0_auto]"
      onClick={() => handleClick(2)}
    >
      {/* Onboarding Page */}
      <div className="bg-gradient-to-b from-[#290349] to-[#592E6D] h-[100vh] w-full font-SF text-white flex flex-col items-center px-[30px] pt-[70px] pb-[20px] gap-[30px]">
        {/* Content */}
        <h1 className="font-[700] text-[1.8rem] opacity-[80%] leading-[2.2rem] w-full">
          Naira Gamified Savings Pool
        </h1>
        <p className="font-[500] text-[0.9rem] opacity-[80%]">
          Grab a chance to win juicy savings in the Naira Gamified Savings Pool.
          No loss prize linked savings with monthly rewards via draws.
        </p>
        <img
          src="assets/images/electBanking1.png"
          alt="electronic banking"
          className="py-[20px]"
        />
        <button
          className="w-full py-[10px] bg-white text-black font-[700] text-[0.9rem] rounded"
          onClick={() => {
            navigate("/signin");
            localStorage.setItem("onboarded", true);
          }}
        >
          Log in
        </button>
        <p
          className="font-[700] text-[0.9rem] opacity-[80%] cursor-pointer"
          onClick={() => {
            navigate("/signup");
            localStorage.setItem("onboarded", true);
          }}
        >
          Sign up / Open account
        </p>
      </div>
    </div>
  );
  const secondSlide = () => (
    <div
      className="md:max-w-[31.25rem] md:m-[0_auto]"
      onClick={() => handleClick(0)}
    >
      {/* Onboarding Page */}
      <div className="bg-gradient-to-b from-[#962C81] to-[#962C81] h-[100vh] w-full font-SF text-white flex flex-col items-center px-[30px] pt-[70px] pb-[20px] gap-[30px]">
        {/* Content */}
        <h1 className="font-[700] text-[1.8rem] opacity-[80%] leading-[2.2rem] w-full">
          Ultimate Jackpot Savings Pool
        </h1>
        <p className="font-[500] text-[0.9rem] opacity-[80%]">
          We heat things up a bit with our ultimate jackpot savings pool where
          one winner takes home a big prize.
        </p>
        <img
          src="assets/images/electBanking2.png"
          alt="electronic banking"
          className="py-[20px]"
        />
        <button
          className="w-full py-[10px] bg-white text-black font-[700] text-[0.9rem] rounded"
          onClick={() => {
            navigate("/signin");
            localStorage.setItem("onboarded", true);
          }}
        >
          Log in
        </button>
        <p
          className="font-[700] text-[0.9rem] opacity-[80%] cursor-pointer"
          onClick={() => {
            navigate("/signup");
            localStorage.setItem("onboarded", true);
          }}
        >
          Sign up / Open account
        </p>
      </div>
    </div>
  );
  const thirdSlide = () => (
    <div
      className="md:max-w-[31.25rem] md:m-[0_auto]"
      onClick={() => handleClick(1)}
    >
      {/* Onboarding Page */}
      <div className="bg-gradient-to-b from-[#3D0158] to-[#3D0158] h-[100vh] w-full font-SF text-white flex flex-col items-center px-[30px] pt-[70px] pb-[20px] gap-[30px]">
        {/* Content */}
        <h1 className="font-[700] text-[1.8rem] opacity-[80%] leading-[2.2rem] w-full">
          Discover how much you know about finance
        </h1>
        <p className="font-[500] text-[0.9rem] opacity-[80%]">
          Check out our interesting finance trivia quizzes to improve your
          money-related decision-making skills. There are prizes to be won.
        </p>
        <img
          src="assets/images/electBanking3.png"
          alt="electronic banking"
          className="py-[20px]"
        />
        <button
          className="w-full py-[10px] bg-white text-black font-[700] text-[0.9rem] rounded"
          onClick={() => {
            navigate("/signin");
            localStorage.setItem("onboarded", true);
          }}
        >
          Log in
        </button>
        <p
          className="font-[700] text-[0.9rem] opacity-[80%] cursor-pointer"
          onClick={() => {
            navigate("/signup");
            localStorage.setItem("onboarded", true);
          }}
        >
          Sign up / Open account
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    if (onBoarded) {
      navigate("/dashboard");
    }
  }, [onBoarded]);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 5000);
  };

  // Set up the interval initially
  useEffect(() => {
    startInterval();

    return () => clearInterval(intervalRef.current);
  }, [totalSlides]);

  // Handle click: change slide and restart interval
  const handleClick = (slideNumber) => {
    setCurrentSlide(slideNumber);
    clearInterval(intervalRef.current);
    startInterval();
  };

  return (
    <div className="relative bg-brandPurp">
      <div className="absolute left-0 right-0 z-10 flex items-center justify-between w-full top-10 px-[30px] md:max-w-[31.25rem] md:m-[0_auto]">
        <div
          className={`bg-white h-[2px] w-[32%] cursor-pointer rounded ${
            currentSlide === 1 ? "opacity-[75%]" : "opacity-[50%]"
          }`}
          onClick={() => handleClick(1)}
        ></div>
        <div
          className={`bg-white h-[2px] w-[32%] cursor-pointer rounded ${
            currentSlide === 2 ? "opacity-[75%]" : "opacity-[50%]"
          }`}
          onClick={() => handleClick(2)}
        ></div>
        <div
          className={`bg-white h-[2px] w-[32%] cursor-pointer rounded ${
            currentSlide === 0 ? "opacity-[75%]" : "opacity-[50%]"
          }`}
          onClick={() => handleClick(0)}
        ></div>
      </div>
      {currentSlide === 1
        ? firstSlide()
        : currentSlide === 2
        ? secondSlide()
        : thirdSlide()}
    </div>
  );
};
