import { useEffect, useState } from "react";
import Moment from "react-moment";
import Timer from "../components/Timer";
import { useContext } from "react";
import { Context } from "../helpers/Context";

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const { state } = useContext(Context);
  const {
    poolInfo,
    jackpotPoolInfo,
    poolInfoLoading,
    jackpotPoolInfoLoading,
    winningPrize,
    jackpotWinningPrize,
  } = state;

  const next = () => {
    setCurrentSlide((current) => (currentSlide < 3 ? current + 1 : 1));
  };
  const prev = () => {
    setCurrentSlide((current) => (currentSlide > 1 ? current - 1 : 3));
  };

  useEffect(() => {
    const intervalId = setInterval(next, 5000);
    return () => clearInterval(intervalId);
  }, [currentSlide]);

  return (
    <div className=" bg-brandPurp relative min-h-[1150px] md:min-h-[811px]">
      {/* Slide 1 */}
      <div
        className={`w-full absolute min-h-[1150px] md:min-h-[811px] transition-opacity duration-1000 ease-in-out ${
          currentSlide === 1 ? "opacity-100" : "opacity-0"
        }`}
      >
        <div
          className={`min-h-[1150px] md:min-h-[811px] bg-slide1 bg-brandPurp bg-no-repeat bg-cover bg-center flex flex-col md:flex-row px-[13.8%] items-center gap-x-[2.75rem] absolute top-0 bottom-0 left-0 right-0
           
          `}
        >
          <div className="">
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] text-center md:text-left md:text-[4.5rem] font-[700] font-AC mt-[106px]">
              Saving is fun
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] md:text-[3rem] text-center md:text-left font-[400] font-AC mt-[24px]">
              Prize-linked savings is more fun
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] font-SF text-[1.125rem] text-center md:text-left font-[400] my-[36px]">
              Improve your financial habits, save money and get an opportunity
              to win rewarding cash prizes monthly.
            </div>
            {/* <div className="hidden md:flex items-center gap-x-[11.13px]">
              <img
                src="/assets/images/appleStore.png"
                alt=""
                className=" w-[12.5rem] h-[3.9582rem] "
              />
              <img
                src="/assets/images/googlePlay.png"
                alt=""
                className=" w-[12.5rem] h-[3.9582rem] hidden md:block"
              />
            </div> */}
          </div>

          <img
            src="/assets/images/iPhone.png"
            alt=""
            className=" w-[19.6294rem] h-[41.228rem]"
          />

          <div
            className="absolute top-[45%] right-[24px] cursor-pointer z-10 hidden md:block"
            onClick={next}
          >
            <img
              src="/assets/images/slideForward.png"
              alt=""
              className=" h-[6.25rem] w-[6.25rem] hidden md:block"
            />
          </div>
        </div>
      </div>
      {/* Slide 2 */}
      <div
        className={`w-full absolute min-h-[1150px] md:min-h-[811px] transition-opacity duration-1000 ease-in-out ${
          currentSlide === 2 ? "opacity-100" : "opacity-0"
        }`}
      >
        <div
          className={`min-h-[1150px] md:min-h-[811px] bg-slide1 bg-brandPurp bg-no-repeat bg-cover bg-center flex flex-col px-[7%] md:px-[13.8%] items-center gap-x-[2.75rem] absolute top-0 bottom-0 left-0 right-0
           
          `}
        >
          <div className=" md:hidden">
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] text-center md:text-left md:text-[4.5rem] font-[700] font-AC mt-[106px]">
              Saving is fun
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] md:text-[3rem] text-center md:text-left font-[400] font-AC mt-[24px]">
              Prize-linked savings <br /> is more fun
            </div>
          </div>

          <div className=" mt-[36px] md:mt-[70px]  text-[rgba(255,255,255,0.90)] text-center md:text-left font-SF text-[1.125rem] font-[400]">
            NAIRA GAMIFIED SAVINGS POOL 0122
          </div>
          <img
            src="/assets/images/gamfiPool.svg"
            alt=""
            className=" w-[14.0043rem] h-[8.8108rem] mt-[84px] md:mt-[15px] mb-[15px]"
          />
          <div className=" text-[rgba(255,255,255,0.90)] font-SF text-[1.125rem] font-[400] mb-[24px] hidden md:block">
            All deposits into the Naira Gamified Savings Pool earn interest
            which is used for the pool prize{" "}
          </div>

          <div className=" w-full lg:w-[45rem] h-[29.0625rem] md:h-[19.625rem] flex flex-col items-center justify-center conic">
            <div className=" text-[rgba(255,255,255,0.90)] text-center font-SF text-[1.125rem] font-[400] mb-[24px] mt-[12px] max-w-[92%] md:hidden">
              All deposits into the Naira Gamified Savings Pool earn interest
              which is used for the pool prize{" "}
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] font-SF text-[1.125rem] font-[400] mb-[12px] mt-[24px]">
              Pool Reward for <Moment format="MMMM">{Date.now()}</Moment>
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] text-[4.5rem] font-[700] font-AC">
              N
              {winningPrize?.amount
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>

            <div className=" text-[rgba(255,255,255,0.87)] text-[1.375rem] font-[400] font-SF">
              Countdown to pool draw
            </div>

            {!poolInfoLoading && (
              <Timer time={poolInfo?.raffle_draw_date} location="landing" />
            )}
          </div>
          <div
            className="absolute top-[45%] left-[24px] cursor-pointer hidden md:block"
            onClick={prev}
          >
            <img
              src="/assets/images/slideBackward.png"
              alt=""
              className=" h-[6.25rem] w-[6.25rem]"
            />
          </div>
          <div
            className="absolute top-[45%] right-[24px] cursor-pointer z-10 hidden md:block"
            onClick={next}
          >
            <img
              src="/assets/images/slideForward.png"
              alt=""
              className=" h-[6.25rem] w-[6.25rem]"
            />
          </div>
        </div>
      </div>
      {/* Slide 3 */}
      <div
        className={`w-full absolute min-h-[1150px] md:min-h-[811px] transition-opacity duration-1000 ease-in-out ${
          currentSlide === 3 ? "opacity-100" : "opacity-0"
        }`}
      >
        <div
          className={`min-h-[1150px] md:min-h-[811px] bg-slide1 bg-brandPurp bg-no-repeat bg-cover bg-center flex flex-col px-[7%] md:px-[13.8%] items-center gap-x-[2.75rem] absolute top-0 bottom-0 left-0 right-0
           
          `}
        >
          <div className=" md:hidden">
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] text-center md:text-left md:text-[4.5rem] font-[700] font-AC mt-[106px]">
              Saving is fun
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] text-[2.25rem] md:text-[3rem] text-center md:text-left font-[400] font-AC mt-[24px]">
              Prize-linked savings <br /> is more fun
            </div>
          </div>
          <div className=" mt-[36px] md:mt-[70px]  text-[rgba(255,255,255,0.90)] text-center md:text-left font-SF text-[1.125rem] font-[400]">
            ULTIMATE JACKPOT SAVINGS POOL 0002
          </div>
          <img
            src="/assets/images/jackport.svg"
            alt=""
            className=" w-[18.75rem] h-[10.0456rem] mt-[35px] md:mt-[15px] mb-[15px]"
          />
          <div className=" text-[rgba(255,255,255,0.90)] font-SF text-[1.125rem] font-[400] mb-[24px] hidden md:block">
            All deposits into the Ultimate Jackpot Savings Pool earn interest
            which is used for the pool prize
          </div>

          <div className=" w-full lg:w-[45rem] h-[29.0625rem] md:h-[19.625rem] flex flex-col items-center justify-center conic">
            <div className=" text-[rgba(255,255,255,0.90)] text-center font-SF text-[1.125rem] font-[400] mb-[24px] mt-[12px] max-w-[92%] md:hidden">
              All deposits into the Ultimate Jackpot Savings Pool earn interest
              which is used for the pool prize
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] font-SF text-[1.125rem] font-[400] mt-[24px] mb-[12px]">
              Pool Reward for <Moment format="MMMM">{Date.now()}</Moment>
            </div>
            <div className=" text-[rgba(255,255,255,0.90)] text-[4.5rem] font-[700] font-AC">
              N
              {jackpotWinningPrize
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>

            <div className=" text-[rgba(255,255,255,0.87)] text-[1.375rem] font-[400] font-SF">
              Countdown to pool draw
            </div>

            {!jackpotPoolInfoLoading && (
              <Timer
                time={jackpotPoolInfo?.raffle_draw_date}
                location="landing"
              />
            )}
          </div>
          <div
            className="absolute top-[45%] left-[24px] cursor-pointer hidden md:block"
            onClick={prev}
          >
            <img
              src="/assets/images/slideBackward.png"
              alt=""
              className=" h-[6.25rem] w-[6.25rem]"
            />
          </div>
        </div>
      </div>

      <div className=" w-full bg-bg1Mobile bg-cover bg-center bg-no-repeat absolute top-[98%] h-[276px] flex flex-col items-center gap-y-[12.9px] pt-[33px] md:hidden">
        <div className=" mb-[18.1px] flex items-center gap-x-[8px]">
          <div
            className={`h-[12px] w-[12px] rounded-[50%] ${
              currentSlide === 1
                ? "bg-[rgba(255,255,255,0.80)]"
                : "bg-[rgba(255,255,255,0.30)]"
            }`}
            onClick={() => {
              console.log(poolInfo, jackpotPoolInfo);
              setCurrentSlide(1);
            }}
          ></div>
          <div
            className={`h-[12px] w-[12px] rounded-[50%] ${
              currentSlide === 2
                ? "bg-[rgba(255,255,255,0.80)]"
                : "bg-[rgba(255,255,255,0.30)]"
            }`}
            onClick={() => setCurrentSlide(2)}
          ></div>
          <div
            className={`h-[12px] w-[12px] rounded-[50%] ${
              currentSlide === 3
                ? "bg-[rgba(255,255,255,0.80)]"
                : "bg-[rgba(255,255,255,0.30)]"
            }`}
            onClick={() => setCurrentSlide(3)}
          ></div>
        </div>
        {/* <img
          src="assets/images/appleStore.png"
          alt=""
          className=" w-[8.7906rem] h-[2.8164rem]"
        />
        <img
          src="assets/images/googlePlay.png"
          alt=""
          className=" w-[8.7906rem] h-[2.8164rem]"
        /> */}
      </div>
    </div>
  );
};

export default Banner;
