import { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Context } from "../../helpers/Context";

const Leaderboard = () => {
  const { state } = useContext(Context);
  const { rewards_details, userInfo } = state;

  const userRank = () => {
    const userIndex = rewards_details?.findIndex(
      (detail) => detail.user.wallet_id === userInfo.user.wallet_id
    );
    return userIndex !== -1 ? userIndex + 1 : ""; // Return rank (index + 1) or empty string if not found
  };

  const userData = (
    <TableRow
      sx={{
        backgroundColor: "#D8B1D4",
        borderRadius: "20px",
        border: "1px solid #D8B1D4",
        "& td": {
          borderBottom: "none", // Remove default bottom border for all cells in this row
        },
      }}
    >
      <TableCell
        sx={{
          textAlign: "center",
          borderRadius: "20px 0 0 20px", // Rounded left corners
          width: "10%", // Set a width for rank column
        }}
      >
        {userRank() === "" ? (
          <span></span>
        ) : userRank() === 1 ? (
          <img src="/assets/images/1stRank.png" alt="1st Rank" />
        ) : userRank() === 2 ? (
          <img src="/assets/images/2ndRank.png" alt="2nd Rank" />
        ) : userRank() === 3 ? (
          <img src="/assets/images/3rdRank.png" alt="3rd Rank" />
        ) : (
          `#${userRank()}`
        )}
      </TableCell>

      <TableCell
        sx={{
          textAlign: "center",
          width: "50%", // Wider column for Username
        }}
      >
        {userInfo?.user?.username}
      </TableCell>

      <TableCell
        sx={{
          textAlign: "center",
          borderRadius: "0 20px 20px 0", // Rounded right corners
          width: "20%", // Narrower column for Total Tokens
        }}
      >
        {userInfo?.total_token_point}
      </TableCell>
    </TableRow>
  );

  const tableData = rewards_details?.map((detail, index) => (
    <TableRow key={index}>
      <TableCell sx={{ textAlign: "center", width: "10%" }}>
        {index + 1 === 1 ? (
          <img src="/assets/images/1stRank.png" alt="1st Rank" />
        ) : index + 1 === 2 ? (
          <img src="/assets/images/2ndRank.png" alt="2nd Rank" />
        ) : index + 1 === 3 ? (
          <img src="/assets/images/3rdRank.png" alt="3rd Rank" />
        ) : (
          `#${index + 1}`
        )}
      </TableCell>

      <TableCell
        sx={{
          textAlign: "center",
          borderRight: "0.2px solid #FF1CF7",
          width: "50%",
        }}
      >
        {detail?.user?.username}
      </TableCell>

      <TableCell sx={{ textAlign: "center", width: "20%" }}>
        {detail?.total_token_point}
      </TableCell>
    </TableRow>
  ));

  return (
    <Box>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#592E6D",
                  width: "10%",
                  textAlign: "center",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#592E6D",
                  width: "70%",
                  textAlign: "center",
                }}
              >
                <p className="font-MV font-[400]">Username</p>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "0.2px solid #FF1CF7",
                  color: "#290349",
                  width: "20%",
                  textAlign: "center",
                }}
              >
                <p className="font-MV font-[400]">Total Tokens</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData}
            {tableData}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default Leaderboard;
