import { Box, Typography } from "@mui/material";
import axios from "../../helpers/axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material/";
import NavLayout from "../NavLayout";
import { Context } from "../../helpers/Context";
import { useAccountDetails } from "../../helpers/Hooks/Api";

function TransactionSkeleton() {
  return (
    <div className="flex items-center justify-between py-[10px] border-b border-[#FF1CF7] border-opacity-50">
      <div className="flex flex-col gap-[10px]">
        <div className="animate-pulse h-[10px] w-[80px] opacity-75 rounded bg-[#592E6D]"></div>
        <div className="animate-pulse h-[10px] w-[60px] opacity-75 rounded bg-[#592E6D]"></div>
      </div>
      <div className="animate-pulse h-[10px] w-[50px] opacity-75 rounded bg-[#592E6D]"></div>
      <div className="animate-pulse h-[10px] w-[50px] opacity-75 rounded bg-[#592E6D]"></div>
    </div>
  );
}

function DepositsWithdrawals({ pageNumber }) {
  useAccountDetails();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { state } = useContext(Context);
  const { accountDetails } = state;
  const navigate = useNavigate();
  const [funds, setFunds] = useState(0);
  const [walletId, setWalletId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [depositTransactions, setDepositTransactions] = useState([]);
  const [withdrawalTransactions, setWithdrawalTransactions] = useState([]);
  const [pageNum, setPageNum] = useState(location.state?.pageNum || 1);
  const [noWithdrawals, setNoWithdrawals] = useState(false);
  const [noDeposits, setNoDeposits] = useState(false);

  // Fetch wallet details
  const fetchData = async () => {
    try {
      const response = await axios.get("/wallet/detail", config);
      setFunds(response?.data?.funds);
      setWalletId(response?.data?.wallet_id);
    } catch (error) {
      console.error(error);
      navigate("/signin");
    }
  };

  // Fetch deposit transactions only if they are not already stored in the state
  const fetchDeposits = async () => {
    if (depositTransactions.length === 0 && !noDeposits) {
      setIsLoading(true);
      try {
        const response = await axios.get("/deposit-history", config);
        if (response.data.length === 0) {
          setNoDeposits(true);
        } else {
          setDepositTransactions(response?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fetch withdrawal transactions only if they are not already stored in the state
  const fetchWithdrawals = async () => {
    // Only make the request if we haven't fetched withdrawals yet and if there are no prior 'no withdrawals' flag
    if (withdrawalTransactions.length === 0 && !noWithdrawals) {
      setIsLoading(true);
      try {
        const response = await axios.get("/withdrawal-history", config);
        if (response.data.length === 0) {
          setNoWithdrawals(true); // Set flag if there are no withdrawals
        } else {
          setWithdrawalTransactions(response?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    if (pageNum === 1) {
      fetchDeposits();
    } else if (pageNum === 2) {
      fetchWithdrawals();
    }
  }, [pageNum]);

  const renderDeposit = (transaction) => (
    <div
      key={transaction.id}
      className="grid grid-cols-3 items-center justify-center cursor-pointer py-[10px] border-b border-[#FF1CF7] border-opacity-50"
      // onClick={() => navigate(`/wallet/${transaction?.uidb}`)}
    >
      <div>
        <p className="text-[0.75rem] font-bold text-[#592E6D] uppercase ">
          {accountDetails?.account_number}
        </p>
        <p className="text-[0.75rem] text-[#592E6D] mt-[6px]">
          {transaction.date_created.slice(0, 10)}
        </p>
      </div>
      <div className="text-[0.75rem] text-[#592E6D] text-center">
        {transaction.is_verified ? "Verified" : "Pending"}
      </div>
      <p className="text-[0.75rem] font-bold text-[#592E6D] text-right">
        NGN{" "}
        {transaction.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </p>
    </div>
  );

  const getStatusText = (status) => {
    switch (status) {
      case 5:
        return "Reversed";
      case 4:
        return "Failed";
      case 3:
        return "Confirmed";
      case 2:
        return "Processing";
      default:
        return "Not Processed";
    }
  };

  const renderWithdrawal = (transaction) => (
    <div
      key={transaction.id}
      className="grid grid-cols-3 items-center justify-center cursor-pointer py-[10px] border-b border-[#FF1CF7] border-opacity-50"
      // onClick={() => navigate(`/wallet/${transaction?.uidb}`)}
    >
      <div>
        <p className="text-[0.75rem] font-bold text-[#592E6D] uppercase ">
          {accountDetails?.account_number}
        </p>
        <p className="text-[0.75rem] text-[#592E6D] mt-[6px]">
          {transaction.time_created.slice(0, 10)}
        </p>
      </div>
      <div className="text-[0.75rem] text-[#592E6D] text-center">
        {getStatusText(transaction.status)}
      </div>
      <p className="text-[0.75rem] font-bold text-[#592E6D] text-right">
        NGN{" "}
        {transaction.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </p>
    </div>
  );

  return (
    <NavLayout
      title="Transaction History"
      content={
        <div className="flex flex-col max-w-[500px] mx-auto pt-[20px] h-[100vh] max-h-[100vh]">
          <div className="flex gap-[30%] border-b-1 border-opacity-50 border-[#FF1CF7] py-[20px] px-[20px] h-[10%]">
            <ArrowBackIos
              sx={{ fontSize: "16px", cursor: "pointer", color: "#fff" }}
              onClick={() => navigate(-1)}
            />
            <p className="text-white">Transaction History</p>
          </div>

          <div className="h-[40%] text-white text-opacity-30">
            <hr />
          </div>

          <div className="bg-white h-[60%] relative flex justify-center">
            <div className="bg-white outline outline-1 outline-[#FF1CF7] shadow-[0px_4px_4px_0px_#00000040] rounded-lg absolute top-[-60%] bottom-[5%] overflow-y-auto max-h-[800px] min-w-[90%] px-[10px] py-[5px] mx-[20px]">
              <div>
                <div className="flex walleTabs">
                  <div
                    className={`Tab flex-1 p-[10px] ${
                      pageNum === 1 ? "border-b-2 border-[#FF1CF7]" : ""
                    }`}
                    onClick={() => setPageNum(1)}
                  >
                    <Typography
                      variant="body2"
                      textAlign="center"
                      sx={{ fontWeight: "600", cursor: "pointer" }}
                    >
                      Deposits
                    </Typography>
                  </div>
                  <div
                    className={`Tab flex-1 p-[10px] ${
                      pageNum === 2 ? "border-b-2 border-[#FF1CF7]" : ""
                    }`}
                    onClick={() => setPageNum(2)}
                  >
                    <Typography
                      variant="body2"
                      textAlign="center"
                      sx={{ cursor: "pointer" }}
                    >
                      Withdrawals
                    </Typography>
                  </div>
                </div>
                <div className="flex justify-between text-[0.75rem] text-[#592E6D] font-normal border-b border-[#FF1CF7] border-opacity-50 pb-[10px] pt-[20px]">
                  <span>ACCOUNT</span>
                  <span>STATUS</span>
                  <span>AMOUNT</span>
                </div>
                <div style={{ marginTop: "5px", color: "#592E6D" }}>
                  {isLoading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <TransactionSkeleton key={index} />
                    ))
                  ) : pageNum === 1 ? (
                    depositTransactions.length > 0 ? (
                      depositTransactions.map(renderDeposit)
                    ) : (
                      <Typography>No Deposit history</Typography>
                    )
                  ) : pageNum === 2 ? (
                    withdrawalTransactions.length > 0 ? (
                      withdrawalTransactions.map(renderWithdrawal)
                    ) : (
                      <Typography>No Withdrawal history</Typography>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default DepositsWithdrawals;
