import { Box, Typography, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { ChevronLeft } from "@mui/icons-material";

import NavLayout from "../NavLayout";
import { useUserProfile } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: ["300px", "300px", "400px", "400px"],
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "20px 10px",
  textAlign: "center",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const Personalinfo = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { state } = useContext(Context);
  const { userProfile } = state;

  useUserProfile(handleOpen);
  return (
    <NavLayout
      title="Personal Information"
      content={
        <Box
          maxWidth={"500px"}
          sx={{
            backgroundColor: "#F5F5F5",
            minHeight: "100vh",
            margin: "0 auto",
          }}
        >
          <ChevronLeft
            sx={{
              color: "#592E6D",
              cursor: "pointer",
              fontSize: "24px",
              fontWeight: "400",
              marginTop: "20px",
              marginLeft: "20px",
            }}
            onClick={() => navigate(-1)}
          />
          <Typography
            sx={{
              color: "#592E6D",
              fontSize: "24px",
              fontWeight: "400",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          >
            Personal Information
          </Typography>

          <Box width={"90%"} margin={"0px auto"}>
            {/* Username */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Username
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.user !== undefined &&
                    userProfile?.user?.username}
                </Typography>
              </Box>
              <Link to="/settings/setusername">
                <Box
                  sx={{
                    color: "#592E6D",
                    fontWeight: "600",
                    textDecoration: "underline",
                  }}
                >
                  Edit
                </Box>
              </Link>
            </Box>

            {/* Name */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.user !== undefined &&
                    userProfile?.user?.first_name +
                      " " +
                      userProfile?.user?.last_name}
                </Typography>
              </Box>
              <Link to="/settings/setname">
                <Box
                  sx={{
                    color: "#592E6D",
                    fontWeight: "600",
                    textDecoration: "underline",
                  }}
                >
                  Edit
                </Box>
              </Link>
            </Box>

            {/* Email */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.user !== undefined && userProfile?.user?.email}
                </Typography>
              </Box>
            </Box>

            {/* Address */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.user !== undefined &&
                    userProfile?.user?.address}
                </Typography>
              </Box>
              <Link to="/settings/setaddress">
                <Box
                  sx={{
                    color: "#592E6D",
                    fontWeight: "600",
                    textDecoration: "underline",
                  }}
                >
                  Edit
                </Box>
              </Link>
            </Box>

            {/* Phone number */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "block" }}>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Phone number
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.user === undefined
                    ? ""
                    : userProfile?.phone === null
                    ? "Not Provided"
                    : userProfile?.phone}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "#592E6D",
                  fontWeight: "600",
                  textDecoration: "underline",
                }}
              >
                <Link to={"/settings/setphone"}>Edit</Link>
              </Box>
            </Box>

            {/* Government ID */}
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "block" }}>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Government ID
                </Typography>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                  }}
                >
                  {userProfile?.identification !== null
                    ? userProfile?.identification?.mode_of_identity
                    : "Not provided"}
                </Typography>
              </Box>
              <Box
                sx={{
                  color: "#592E6D",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textAlign: "right",
                }}
              >
                <Link
                  to={
                    userProfile?.identification !== null ? "" : "/settings/id"
                  }
                >
                  Add
                </Link>
                <Typography
                  sx={{
                    color: "#592E6D",
                    fontSize: "14px",
                    fontWeight: "200",
                    paddingTop: "5px",
                    textDecoration: "none",
                  }}
                >
                  {userProfile?.identification !== null
                    ? userProfile?.identification?.status
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <BasicModal open={open} handleClose={handleClose} />
        </Box>
      }
    />
  );
};

function BasicModal(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please Complete KYC Level 2
          </Typography>
          <Typography my={"10px"}>
            Please Complete KYC Level 2 by updating your profile picture and
            phone number
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "GrayText", textDecoration: "underline" }}
          >
            <Link to={"/settings/setphone"}> Update Phone Number </Link>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "GrayText", textDecoration: "underline" }}
          >
            <Link to={"/settings/avatar"}> Upload profile picture </Link>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default Personalinfo;
