import { Box, Typography } from "@mui/material";
import NavLayout from "../NavLayout";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useBVNUpdate, useAccountDetails } from "../../helpers/Hooks/Api";
import { Context } from "../../helpers/Context";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

const BVN = () => {
  const { state } = useContext(Context);
  const { accountDetails } = state;
  const navigate = useNavigate();
  const { isLoading } = useAccountDetails();

  const [bvn, setBvn] = useState("");
  const [fieldError, setFieldError] = useState("");
  const {
    BVNStatus,
    checkBVN,
    serverError,
    setServerError,
    updateBVN,
    shouldUpdate,
  } = useBVNUpdate();

  const updateBvn = () => {
    setFieldError("");

    if (bvn?.length === 11) {
      shouldUpdate
        ? updateBVN({ bvn })
        : setServerError("The details you entered are invalid!");

      return;
    } else {
      setFieldError("BVN should not be less than 10 digits");
    }
  };

  const handleChange = (e) => {
    setBvn(e.target.value);
    if (e.target.value.length === 11) {
      checkBVN({ bvn: e.target.value });
    } else {
      setFieldError("");
      setServerError("");
    }
  };

  useEffect(() => {
    if (bvn.length === 11) {
      setFieldError(BVNStatus);
    }
  }, [BVNStatus, bvn]);

  return (
    <NavLayout
      title="Update-BVN"
      content={
        <Box
          maxWidth={"500px"}
          sx={{ margin: "0 auto", paddingBottom: "5rem" }}
        >
          <Box>
            <Box
              sx={{
                padding: "24px 20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "16px",
                    cursor: "pointer",
                    position: "absolute",
                    left: "0",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography variant="body1">Wallet Payout</Typography>
              </Box>
              <div className=" bg-white max-w-[500px] h-[707px] rounded-[10px] mt-[21px] p-[16px_10px]">
                {/* <h2 className=" text-center text-[0.875rem] text-[#2E084D] font-AR ">
                  BVN
                </h2>
                <p className=" text-center text-[0.875rem] text-[#2E084D] font-AR mt-[34px] ">
                  Your BVN is only for the sole purpose of verification.
                </p> */}
                {isLoading ? (
                  <div className="h-full flex flex-col items-center m-[15px_0]">
                    <Oval
                      height={80}
                      width={80}
                      color="#290349"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#592e6d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                ) : accountDetails?.account_number ? (
                  <div>
                    <h2 className="text-center text-[0.875rem] text-[#2E084D] font-AR">
                      BVN Verification Update
                    </h2>
                    <div className="mt-[63px]">
                      <label className="text-[0.75rem] font-AR font-[700] flex justify-between">
                        BVN
                        <img
                          src="/assets/images/VectorVerified.png"
                          alt="verified"
                          className="mr-[10px]"
                        />
                      </label>
                      <p className="font-AR text-[0.75rem]">Verified</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h2 className="text-center text-[0.875rem] text-[#2E084D] font-AR">
                      BVN
                    </h2>
                    <div className="flex flex-row mt-[34px]">
                      <img
                        src="/assets/images/iInfo.png"
                        alt="information"
                        className="w-[18px] h-[18px]"
                      />
                      <p className="text-center text-[0.875rem] text-[#2E084D] font-AR">
                        Your BVN is only for the sole purpose of verification
                        and creation of your Onecap’s virtual account number.
                      </p>
                    </div>
                    <div className="mt-[63px]">
                      <label className="text-[0.75rem] text-brandPurp font-AR font-[700] flex justify-between">
                        BVN
                      </label>
                      <input
                        type="text"
                        className="w-full p-[12px_8px] rounded-[4px] border-[1px] border-brandPurp outline-none mt-[5px] text-[rgba(0,0,0,0.50)] text-[0.75rem]"
                        onChange={handleChange}
                        style={{ border: "1px solid #592E6D" }}
                      />
                      <small
                        className={`${
                          serverError === "BVN updated successfully"
                            ? "text-[#25CF43]"
                            : "text-[#F00]"
                        } text-[0.625rem] font-AR`}
                      >
                        {fieldError ? fieldError : serverError}
                      </small>
                    </div>
                    <button
                      className="bg-brandPurp text-white py-[12px] rounded-[10px] w-full mt-[113px] text-[0.75rem] font-AR font-[700]"
                      onClick={updateBvn}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default BVN;
